.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: auto;

  .main {
    flex: 1;
  }
}
