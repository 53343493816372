@import 'src/assets/css/utilities/index';

.error-text {
  font-size: $p_font_size;
  color: $c_danger;
  margin: 5px 0px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
