.navItemContainer {
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-right: 17px;
  }
}

.label {
  font-size: 15px;
  line-height: 18px;
  color: #505e63;
  font-weight: 400;
  margin-left: 7px;
}

.selected {
  font-weight: 600;
}
