@import 'src/assets/css/utilities/index';

.container {
  width: 100%;
  height: 100vh;
  padding: 30px;

  h3 {
    color: $c_danger;
  }

  p {
    color: $c_secondary;
  }
}
