@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../../../node_modules/bootstrap/scss/variables';

$header-height: 88px;

$border-radius: 10px;

$c_blue1: #57d4ff;
$c_blue2: #00a3db;
$c_blue3: #007da8;
$c_blue4: #a8e9ff;
$c_blue5: #505e63;
$c_blue6: #9fbac2;
$c_blue7: #00a4dd;
$c_red: #ff0000;
$c_red2: #eb5757;
$main-background: #f8f8f8;
$c_primary: #00ca92 !default;
$c_secondary: #414a59;
$c_grey: #babdbf;
$c_danger: #de1118;
$c_info: #2c97de;
$c_blue: #007fff;
$c_blue_button: #3b95d4;
$c_black: #000000;
$widget-background: #ffffff;
$separator: #dde6e9;
$widget-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08);
$c_white: #ffffff;
$c_light_grey: #f5f5f5;
$c_ant_primary: #1890ff;
$c_verified: #e7f8f8;
$c_pending: #fff6e4;
$c_not_verified: #ffebe4;
$c_verified_text: #1ec1c3;
$c_pending_text: #ffb100;
$c_not_verified_text: #ff4500;

$c_custom_grey_1: #b2b2b2;
$c_background_grey_1: #f0f6f9;
$c_background_grey_2: #f3f1f6;
$c_table_th: #f5f6fa;
$c_shadow: #e2e2e2;

$c_status_verified: #a6d997;
$c_status_failed: #db615c;
$c_status_pending: #df8244;
$c_status_review: #4cafea;
$c_status_active: #87d271;
$c_status_inactive: #e3eddd;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1220px,
) !default;

$h1_font_size: 3.75rem;
$h2_font_size: 2.875rem;
$h3_font_size: 1.875rem;
$h4_font_size: 1.375rem;
$p_font_size: 1rem;
$sm_font_size: 0.875rem;

$large: 65px;
$medium: 55px;
$small: 45px;
