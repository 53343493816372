@import 'src/assets/css/utilities/index';

.appContainer {
  padding: 27px 64px;
  background-color: $main-background;
  height: 100vh;
  overflow: scroll;
  @media (max-width: 768px) {
    padding: 18px 16px 30px 16px;
  }
}

.header {
  background-color: $widget-background;
  height: 65px;
  box-shadow: $widget-shadow;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 0 25px;
  margin: 0 0 49px 0;
  border-radius: 8px;
  @media (max-width: 1200px) {
    padding: 0 15px;
    margin: 0 0 28px 0 !important;
  }
}
.navLink {
  @media (max-width: 576px) {
    display: none;
  }
}
.navigationSection {
  display: flex;
  gap: 20px;
  .logoAdmin {
    height: 32px;
  }
}
.optionMobile {
  gap: 15px;
  display: none;
  @media (max-width: 567px) {
    display: flex;
  }
}
.customProfile {
  display: flex;
  align-items: center;
  .optionProfile {
    .body {
      display: flex;
      align-items: center;
      gap: 15px;
      .text {
        margin: 0 15px 0 0;
        .name {
          font-weight: 500;
          font-size: 14px;
          color: #505e63;
        }
        .fullName {
          font-size: 12px;
          color: #505e63;
        }
      }
      .icon {
        height: 65px;
        font-weight: 500;
        font-size: 14px;
        color: #505e63;
        cursor: pointer;

        button {
          border: none;
          background: none;
          cursor: pointer;
        }
        .icLogout {
          cursor: pointer;
          margin: auto;
          margin-right: 8px;
          width: 32px;
          height: 32px;
          padding: 4px;
          background: #f8f8f8;
          border-radius: 8px;
        }
      }
    }
    @media (max-width: 576px) {
      display: none;
    }
  }
  .iconMobile {
    border: none;
    background: none;
    display: none;
    @media (max-width: 576px) {
      display: inline-block;
    }
  }
}

.dropdown {
  padding: 18px 6px;
  .menuItem {
    padding: 5px 0;
    .navLinkDropdown {
      display: block;
      min-width: 140px;
      padding: 4px 14px;
      border-radius: 8px;

      &:hover {
        background-color: $main-background;
      }
      .icon {
        width: 32px;
        height: 32px;
        padding: 4px;
        background-color: $main-background;
        border-radius: 8px;
      }
      .labelText {
        font-size: 15px;
        line-height: 18px;
        color: #505e63;
        margin-left: 7px;
      }
    }
  }
}

.customDrawer {
  .body {
    .logo {
      margin: 0 0 50px 14px;
      width: 144px;
      height: 33px;
      overflow: hidden;
      .logoAdmin {
        display: block;
        max-width: 100%;
      }
    }
    .navLinkOnDrawer {
      display: block;
      margin: 0 0 25px 14px;
      .icon {
        width: 32px;
        height: 32px;
        padding: 4px;
        background-color: $main-background;
        border-radius: 8px;
      }
      .labelText {
        font-size: 15px;
        line-height: 18px;
        color: #505e63;
        margin-left: 7px;
      }
    }

    .footer {
      background: #f8f8f8;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 11px 17px;
      .info {
        .name {
          font-weight: 500;
          font-size: 14px;
          color: #505e63;
        }
        .fullName {
          font-size: 12px;
          color: #505e63;
        }
      }
      button {
        border: none;
        background: none;
      }
    }
  }
}
.menuContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 20px;
  background: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 260px;
  height: 105px;
  border-top: 20px solid #d2d2d2;
  .content {
    @include reset-button;
    font-size: 15px;
    line-height: 8px;
    color: #969696;
    font-weight: 400;
  }
  .content.active {
    font-weight: 700;
  }
}
.profileContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;
  padding: 4px 11px 3px 20px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 160px;
  height: 90px;
  .content {
    @include reset-button;
    font-size: 15px;
    line-height: 8px;
    color: #969696;
    font-weight: 400;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
  }
  .content.active {
    font-weight: 700;
    font-size: 15px;
  }
}
