@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin reset-button {
  border: none;
  user-select: none;
  outline: none;
  background: transparent;
  padding: 0;
  display: block;
  text-align: left;
  -webkit-appearance: none;
  -moz-appearance: none;
}

@mixin common-box-shadow {
  box-shadow: 0px 0px 25px -5px rgba($black, 0.3);
}

@mixin line-clamp($line-clamp) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line-clamp;
  -webkit-box-orient: vertical;
  word-break: break-word;
  white-space: normal;
}
@mixin field {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@mixin border-verified {
  background-color: rgba($color: $c_primary, $alpha: 0.1);
  color: $c_primary;
  border: none;
  border-radius: 17px;
  padding: 3px 12px 6px 12px;
  margin-bottom: 8px;
  margin-top: 5px;
  width: auto;
}
@mixin border-expired {
  background-color: rgba($color: $c_danger, $alpha: 0.1);
  color: $c_danger;
  border: none;
  border-radius: 17px;
  padding: 3px 12px 6px 12px;
  margin-bottom: 8px;
  margin-top: 5px;
  width: auto;
}

@mixin common-text-box {
  padding: 0 10px;
  border: 1px solid $c_white;
  border-radius: 16px;
  font-size: $p_font_size;
  height: 50px;
  background-color: $c_light_grey;
  text-align: left;
}
