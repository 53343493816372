@import 'bootstrap';
@import 'utilities/variable';
@import 'utilities/functions';
@import 'src/components/old-version/component.scss';

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'Fira Sans', sans-serif;
  src: url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
}

html,
body {
  // line-height: initial !important;
  overflow-x: hidden;
}
* {
  font-family: 'Fira Sans', sans-serif;
}

html {
  font-size: 16px;

  @include media-breakpoint-between(sm, lg) {
    font-size: 15px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 14px;
  }

  @include media-breakpoint-down(xs) {
    font-size: 13px;
  }
}

a {
  text-decoration: none;
  font-weight: 500;
  font-size: $p_font_size;
  color: $c_primary;

  &:hover {
    text-decoration: none;
  }
}

button {
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }

  touch-action: manipulation;
}

h1,
h2,
h3,
h4,
h5 {
  color: $c_secondary;
}

h1 {
  font-size: $h1_font_size;
}

h2 {
  font-size: $h2_font_size;
}

h3 {
  font-size: $h3_font_size;
  font-weight: bold;
}

h4 {
  font-size: $h4_font_size;
  font-weight: bold;
}

h5 {
  font-size: $p_font_size;
  font-weight: bold;
}

hr {
  border-top: 1.5px solid rgba($c_grey, 0.1);
}

p {
  font-size: $p_font_size;
  color: $c_secondary;
}

input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}
.reset-button {
  @include reset-button;
}

table thead th {
  background: $c_table_th;
  vertical-align: bottom;
  padding: 10px 10px;
  font-size: $p_font_size;
  text-align: left;
}

table tbody td {
  border-bottom: 1px solid $c_background_grey_1;
  padding: 10px 10px;
  font-size: $p_font_size;
  text-align: left;
}

button:focus {
  outline: none !important;
}

* {
  scrollbar-width: auto;
  scrollbar-color: #bfbfbf #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 11px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 6px;
  border: -5px none #ffffff;
}
