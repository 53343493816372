@import 'functions';
@import 'mixin';
@import 'variable';

.transparent-modal {
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }
}

.anticon.anticon-right {
  transform: translateY(-2px);
}

.anticon.anticon-left {
  transform: translateY(-2px);
}

.ant-pagination-item {
  a {
    font-weight: normal;
  }
}

.ant-modal-footer {
  .ant-btn-loading-icon {
    transform: translateY(-3px);
  }
}

.ant-picker-input {
  input {
    &:focus-visible {
      outline: none;
    }
    &::placeholder {
      font-size: 16px;
      color: grey;
    }
    &.inputFields {
      width: 90%;
      margin: 0 15px 0 0;
    }
  }
}
[ant-click-animating-without-extra-node='true']::after {
  display: none;
}

.timePickerHeader {
  position: absolute;
  top: 3px;
  right: 26px;
  width: 60px;
  height: 30px;
  background-color: #ffffff;
  text-align: center;
  p {
    color: #000000d9;
    font-size: 14px;
    font-weight: 500;
  }
}

.ant-picker-now-btn {
  color: #1890ff !important;
}
