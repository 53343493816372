@import 'src/assets/css/utilities/index';

.nav-drawer-customer {
  .ant-drawer-mask {
    background: rgba(46, 66, 73, 0.7);
    backdrop-filter: blur(8px);
    &::after {
      display: inline-block;
      content: url('../../assets/images/common/ic-close-mobile.svg');
      position: inherit;
      top: 32px;
      right: 35px;
    }
  }
  .ant-drawer-content-wrapper {
    box-shadow: 5px 0px 19px rgba(0, 0, 0, 0.18) !important;
    .ant-drawer-content {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
}
.dropdown-content_menu {
  border-radius: 8px !important;
  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: $c_white;
  }
}

@media (min-width: 500px) {
  .nav-drawer-customer {
    .ant-drawer-content-wrapper {
      max-width: 400px !important;
    }
  }
}
